body {
  @include font-smoothing;
}


/* navbar dark */
.navbar-dark {
  background-color: #282F37 !important;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);

  @include transition(all .4s);

  @media(min-width: 992px) {
    padding: 0 1rem;
  }

  .navbar-brand {
    font-size: 21px;
    letter-spacing: .5px;
    color: #fff;
    @include transition(color .2s linear);
    @media(max-width: 767px) {
      width: 60%;
    }
  }
  
  .dropdown-menu {
    @media(min-width: 992px) {
      border-color: #fbfbfb;
      box-shadow: 0 13px 20px rgba(0, 0, 0, 0.07);
      margin-top: -1px;
      border-radius: 0 0 0.25rem 0.25rem;

      .dropdown-item {
        color: #555;
        padding: 4px 1.5rem;
        padding-right: 30px;

        &:active {
          background-color: #f7f7f9;
        }
      }
    }
  }

  .nav-item {
    .nav-link {
      color: #dadada;
      font-weight: 500;
      font-size: 13px;

      @include transition(color .15s linear);

      @media(min-width: 992px) {
        margin: 0 9px;
        padding: 26px 10px 22px;
        line-height: 1;
      }

      &:hover,
      &:focus {
        color: #fff;
        background: none;
      }

      &--rounded {
        @media(min-width: 1200px) {
          text-transform: capitalize;
          border: 1px solid #868DA0;
          color: #E3E6EA;
          border-radius: 25px;
          margin-left: 20px;
          margin-top: 22px;
          padding: 8px 20px 9px;

          @include transition(all .15s ease-out);

          &:hover {
            border-color: #fff;
          }

          &:active {
            @include transform(scale(0.93));
          }
        }
      }

      i {
        margin-left: 4px;
        font-size: 10px;
      }
    }
  }
}

/* extra bg-transparent utility for navbar dark and light */
.navbar.bg-transparent {
  @media(min-width: 992px) {
    background-color: transparent !important;
    box-shadow: none !important;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;
  }

  .dropdown-menu {
    @media(min-width: 992px) {
      border-radius: 0.25rem;
      margin-top: -7px;
    }
  }
}

.navbar-dark.bg-transparent {
  .nav-item {
    .nav-link {
      color: #fff;

      &--rounded {
        @media(min-width: 1200px) {
          border-color: #fff;
          color: #fff;

          &:hover {
            background-color: #fff;
            color: #333;
          }
        }
      }
    }
  }
}


/* responsive offcanvas */
.offcanvas-collapse {
  @media (max-width: 991px) {
    position: fixed;
    top: 56px;
    bottom: 0;
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    overflow-y: auto;
    background-color: var(--gray-dark);

    @include transition(transform .3s ease-in-out);
    @include transform(translateX(100%));

    &.open {
      @include transform(translateX(-1rem));
    }
  }
}

/* off-canvas navigation */
.off-wrapper {
  position: relative;
  overflow: hidden;

  &.active {
    &.off-wrapper-left {
      .off-wrapper-content {
        @include transform(translate3d(290px, 0, 0));
      }
    }

    &.off-wrapper-right {
      .off-wrapper-content {
        @include transform(translate3d(-290px, 0, 0));
      }
    }

    .off-wrapper-content {
      box-shadow: 5px 0px 20px 0px rgba(0, 0, 0, 0.3);
    }

    .off-wrapper-menu {
      @include transform(translate3d(0, 0, 0) !important);
    }
  }

  &.off-wrapper-left {
    .off-wrapper-menu {
      left: 0;

      @include transform(translate3d(-50%, 0, 0));
    }
  }

  &.off-wrapper-right {
    .off-wrapper-menu {
      right: 0;

      @include transform(translate3d(50%, 0, 0));
    }
  }

  .off-wrapper-content {
    position: relative;
    z-index: 999;
    background: #fff;

    @include transition(transform 0.3s ease-out);
  }

  .off-wrapper-menu {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    z-index: 1;
    width: 290px;
    height: 100%;
    background: $offCanvasSidebarBg;
    padding: 24px 20px;

    @include transition(transform 0.3s ease-out);

    .menu-title {
      font-size: 1.4rem;
      color: $offCanvasTitleColor;
      margin-bottom: 25px;
      padding-left: 0.5em;
    }

    .nav-link {
      color: $offCanvasLinkColor;
      font-size: 15px;

      i {
        margin-left: 4px;
        font-size: 10px;
      }
    }

    .dropdown-menu {
      position: static;
      float: none;
      display: none;
    }

    .show>.dropdown-menu {
      display: block;
    }

    .dropdown-toggle::after {
      display: none;
    }
  }
}

.home-hero {
  background: url('#{$imagesPath}/erp-digiflex.jpg');
  background-position: center 0;
  background-size: cover;
  background-attachment: fixed;
  height: 590px;
  padding-top: 150px;
  overflow: hidden;
  position: relative;

  @media(max-width: 1024px) {
    background-attachment: inherit;
  }

  @media(max-width: 767px) {
    text-align: center;
    padding-top: 60px;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(26, 35, 62, 0.58);
  }

  .container {
    position: relative;
    z-index: 10;
  }

  h2 {
    color: #fff;
    margin-top: 40px;
    font-size: 44px;
    font-weight: 200;
    line-height: 54px;
    width: 590px;
    text-shadow: 0 2px 1px rgba(0, 0, 0, 0.11);

    @include animationDelay(.1s);

    @media(max-width: 991px) {
      font-size: 38px;
      line-height: 52px;
    }

    @media(max-width: 767px) {
      width: auto;
    }
  }

  p {
    color: #DFEDFF;
    font-weight: 400;
    margin-top: 30px;
    font-size: 17px;
    line-height: 27px;

    @include animationDelay(.5s);
  }
  
  .btn-pill {
    margin-top: 30px;
    font-weight: 600;
    padding: 13px 44px 16px;

    &:active {
      border-color: #3b6b23;
    }

    @include animationDelay(.5s);

    @media(max-width: 767px) {
      margin-top: 30px;
    }   
  }
}
  .carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 6rem;
    bottom: 6rem;
  }

.announcement-news {
  text-align: center;
  padding: 25px 0;
  background: #F9F9F9;
  border-bottom: 1px solid #DADADA;
  color: #6E6E6E;
  font-size: 15px;

  span {
    display: inline-block;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #8e82de;
    font-weight: 700;
    padding: 2px 7px;
    border-radius: 10px;
    font-size: 11px;
    position: relative;
    top: -1px;
    margin-right: 5px;
  }

  strong {
    font-weight: 600;
    color: #333;
    margin-right: 10px;
  }

  a {
    color: #0C80E4;
    margin-left: 5px;

    @media(max-width: 991px) {
      display: block;
      margin-top: 7px;
    }
  }
}

.section-image {
  max-width: 100%;
  padding-bottom: 1rem;
}
.section-anchor {
  margin-bottom: 6rem;

}


.highlighted-area {
  background: #495a7a; 
  padding: 60px 0;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;

  h3 {
    margin: 0;
    font-size: 20px;
    letter-spacing: 2px;
    text-align: center;
    color: #ddd;
    text-transform: uppercase;
    font-weight: 600;
    padding-bottom: 2rem;
  }
  p {
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    text-shadow: 1px 1px #5B6162;
  }
}



.features {
  .header {
    position: relative;
    margin-bottom: 2rem;

    h3 {
      text-align: center;
      font-size: 27px;
      color: #555;
    }

    p {
      color: #666;
      font-size: 15px;
      text-align: center;
      line-height: 25px;
      width: 75%;
      margin: 0 auto;
      margin-top: 12px;
    }
  }

  .feature {
    margin-bottom: 2rem;
    @media(max-width: 767px) {
      text-align: center;
    }
  

    h3 {
      font-size: 17px;
      color: #2F363F;
      line-height: 24px;

      i {
        margin-right: 15px;
        position: relative;
        top: 1px;

      }
    }

    p {
      font-size: 15px;
      margin-top: 25px;
      line-height: 25px;
      color: #81878C;
    }

    a {
      position: relative;
      font-weight: 500;
      text-decoration: none;

      @include transition(all .2s);

      @media(max-width: 767px) {
        top: 0;
      }

    }
  }
}

.zebra-stripe {
  background-color: #eee;
  padding-top: 35px;
  padding-bottom: 35px;
  @media(min-width: 767px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}






.text-block {

  > .container {
    @media (min-width: 1200px) {
      width: 880px;
    }
  }
}
.text-block-content {
  margin-top: 50px;

  @media(max-width: 767px) {
    margin-top: 30px;
  }

  h1, h2, h3, h4, h5 {
    margin: 45px 0 20px;
    font-size: 23px;
  }

  p {
    font-size: 16px;
    color: #6b6b6b;
    line-height: 29px;
    margin-bottom: 20px;

    @media(max-width: 991px) {
      font-size: 14px;
    }
  }
}

.spacial-features {
  margin-top: 110px;
  margin-bottom: 70px;
  @media(max-width: 767px) {
    margin-top: 60px;
  }

  .header {
    text-align: center;

    h2 {
      font-size: 26px;
      letter-spacing: .1px;
      font-weight: 400;

      @media(max-width: 767px) {
        font-size: 23px;
      }
    }

    p {
      color: #5A6C90;
      margin-top: 15px;
      font-size: 16px;

      @media(max-width: 767px) {
        font-size: 15px;
      }
    }
  }

  .feature {
    margin-top: 50px;

    @media(max-width: 767px) {
      text-align: center;
    }

    h3 {
      color: #2F4166;
      font-size: 18px;
      margin-top: 20px;

      .badge-new {
        background-image: linear-gradient(-90deg, #609CFF 0%, #8D7FFF 100%);
        text-transform: uppercase;
        font-size: 12px;
        color: #fff;
        border-radius: 4px;
        padding: 3px 8px;
        letter-spacing: 0.5px;
        font-weight: bold;
        position: relative;
        top: -3px;
        left: 7px;
      }
    }

    p {
      color: #7080A2;
      line-height: 25px;
      font-size: 15px;
      margin-top: 15px;
      width: 87%;

      @media(max-width: 767px) {
        width: auto;
      }
    }
  }
}




.nav-tabs-dropdown {
  display: block;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #44c8f5;
  @media(min-width: 992px) {
    display: none;
  }

  &:before {
    content: "\f107";
    font-family: "FontAwesome";
    position: absolute;
    right: 30px;
  }
}

.nav-tabs-wrapper { 
  display: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  text-align: center;
  @media(min-width: 992px) {
      display: flex!important;
      text-align: left;
  }
  .nav-link {
    padding: 0.5rem 0.40rem;
    @media(min-width: 1200px) {
      padding: 0.5rem 0.55rem;
    }
  }
}

.tab-pane {
  dl, ol, ul {
    padding-left: 1rem;
  }
}

@media(max-width: 992px) {
  .nav-tabs-vertical {
    min-height: 1.25rem;
    padding: 1rem;
    margin-bottom: 1.25rem;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
  }
  .tab-content {
    border: 1px solid #e3e3e3;
    padding-left: 1rem;
    padding-right: 1rem;
    img {
      max-width: 100%;
      padding: 1rem 0;
    }
  }
}
@media(min-width: 992px) {
  .nav-tabs-vertical {
    border-bottom: 0;
  }
  .nav-tabs-vertical  li {
    margin-bottom: 0.25rem;
    .nav-link{
      background-color: rgba(0, 121, 173, .25);
      color: darken(#17a2b8, 10%);
      border: none;
      border-radius: 0;
      margin-bottom: 0rem;

      &:hover, &:focus, &.active {
        background-color: darken(#17a2b8, 5%) !important;
        color: white;

        &:before {
          content: ' ';
          height: 0;
          position: absolute;
          width: 0;
          margin-top: 0.25rem;
          right:-1px;
          border: 0.5rem solid transparent;
          border-left-color: darken(#17a2b8, 5%);
        }
      }
    }
  }
}


.contact-support {

  .container2 {
    clear: left;
    float: left;
    width: 100%;
    overflow: hidden;
    border-top: 1px solid #e2e9f0;
    border-bottom: 1px solid #e2e9f0;

    .container1 {
      float: left;
      width: 100%;
      position: relative;
      right: 50%;
      background: #f5f7f9;
      border-right: 1px solid #e2e9f0;

      @media(max-width: 991px) {
        right: auto;
      }
    }
  }

  .col-left {  
    float: left;  
    position: relative;
    width: 100%;
    left: 0;
    padding: 30px;
    overflow: hidden;

    @media(min-width: 768px) { 
      padding: 50px 70px 70px;
    }
    @media(min-width: 992px) {  
      width: 50%;
      left: 50%;
    }



    .contact-form {
      float: none;
      margin-left: auto;

      @media(min-width: 768px) {
        width: 80%;
        margin-left: 10%;
      }
      @media(min-width: 992px) {
        float: right;
      }



        strong {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: 600;
          color: #444;
          letter-spacing: .5px;
        }
        h3 {
          font-size: 13px;
          color: #70A9CD;
          text-transform: uppercase;
          line-height: 17px;
          letter-spacing: 2px;
          font-weight: 600;
        }
    
        p {
          color: #657790;
          margin-top: 12px;
          line-height: 24px;
          width: 90%;
        }
      
    }
  }

  .col-right {
    float: left;
    width: 100%;
    left: 0;
    position: relative;
    overflow: hidden;
    padding: 30px;


    @media(min-width: 768px) {
      padding: 50px 70px 70px;
    }
    @media(min-width: 991px) {
      width: 50%;
      left: 50%;
    }

    .info {
      width: auto;

      @media(min-width: 991px) {
        width: 340px;
      }
    }

    h3 {
      font-size: 13px;
      margin-top: 20px;
      color: #70A9CD;
      text-transform: uppercase;
      line-height: 17px;
      letter-spacing: 2px;
      font-weight: 600;
    }

    h4 {
      font-size: 27px;
      color: #4B4B4B;
      line-height: 37px;
      font-weight: 300;
      margin-top: 15px;
    }

    p {
      font-size: 15px;
      color: #7B8398;
      line-height: 27px;
      margin-top: 30px;
    }
  }
}


/*  Business Partners */
.business-partners {
  margin-top: 110px;
  text-align: center;

  // @media(max-width: 767px) {
  //   margin-top: 110px;
  // }

  header {
    font-size: 18px;
    margin-bottom: 30px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%;
      height: 1px;
      background: #E9E9E9;
      top: 11px;
    }

    h4 {
      position: relative;
      top: -8px;
      padding: 0 40px;
      background: #fff;
      color: #97A8B8;
      display: inline-block;
      text-transform: uppercase;
      font-size: 13px;
      letter-spacing: 1.8px;
      word-spacing: 1.6px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  .img-responsive {
    margin: 0 auto;
  }
}

/* Above footer */
.city-bg {
  background: url('#{$imagesPath}/city-bw.jpg');
  height: 370px;
  margin-bottom: -200px;
  margin-top: -60px;
  background-size: cover;
  background-position: center center;
  @media(max-width: 767px) {
    height: 270px;
    margin-bottom: -140px;
    margin-top: -60px;
  }
}

/* footer */
.footer {
  margin-top: 200px;
  padding: 70px 0 90px;
  background: #353D4D;
  position: relative;

  @media(max-width: 767px) {
    margin-top: 130px;
    padding-bottom: 60px;
    text-align: center;
  }

  .col-md-3, .col-md-4 {
    @media(max-width: 767px) {
      margin-bottom: 35px;
    }
  }
  color: #BABFCD;
  .menu-title {
    color: #fff;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 600;
    margin-bottom: 20px;
    @media(max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .menu-link {
    color: #97b0c5;
    display: block;
    margin-bottom: 2px;

    @include transition(all .2s);

    &:hover {
      color: #fff;
    }
  }
  .go-top {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: -25px;
    width: 50px;
    height: 50px;
    font-size: 12px;
    letter-spacing: 1px;
    background: #fff;
    padding-left: 2px;
    padding-top: 8px;
    border-radius: 50%;
    text-align: center;
    color: #222;
    text-decoration: none;

    i {
      display: block;
      font-size: 16px;
    }
  }

  

  a.social {
    width: 30px;
    height: 30px;
    background: #BABFCD;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    margin-right: 12px;

    @include transition(all .2s);

    &:hover {
      background: #fff;
    }


    i {
      position: absolute;
      color: #3F4552;
      font-size: 19px;

      &.fa-facebook {
        top: 7px;
        left: 10px;
      }

      &.fa-twitter {
        top: 7px;
        left: 6px;
      }

      &.fa-dribbble {
        top: 6px;
        left: 7px;
      }

      &.fa-github {
        top: 6px;
        left: 7px;
      }
    }
  }

  .bottom {
  color: #68849a;
  position: absolute;
  bottom: 18px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 13px;

  a {
    color: #68849a;
    margin-left: 5px;
    display: inline-block;

    @include transition(all .2s);

    &:hover {
      color: #fff;
    }
  }
}
}
