

/*****************************************/
/* Slider Element */
/*****************************************/
.slider-component {
  margin: 0 auto;
  position: relative;
  max-width: 100%;
  width: 100%;
  height: 680px;
  background: #333;

  @media(max-width: 991px) {
    height: 600px;
  }

  @media(max-width: 767px) {
    height: 500px;
  }

  > .carousel-inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .carousel-item {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;

    .pic {
      display: block;
      min-width: 100%;
      max-width: none;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      background-size: cover; 
      background-position: center center;
    }
  }

  .carousel-control-next, .carousel-control-prev, .carousel-indicators {
    z-index: 1003;
  }

}



.home-slider-header {
  position: relative;
  top: -1px;

  .slider-component {
    height: 650px;

    .carousel-item {
      .carousel-caption {
        bottom: auto;

        &--center {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
        }

        &--left {
          margin-right: auto;
          text-align: left;
        }

        &--right {
          margin-left: auto;
          text-align: right;
        }
        max-width: 100%;
        @media(min-width: 767px) {
          max-width: 50%;
        }

        @media (min-width: 1200px) {
          max-width: 40%;
          .container {
              width: 950px;
          }
        }

        h2 {
          color: #fff;
          font-size: 27px;
          line-height: 35px;
          font-weight: 400;
          text-shadow: 1px 1px rgba(0, 0, 0, 0.17);
          margin-top: 100px;


          @media(min-width: 767px) {
            margin-top: 160px;
            font-size: 30px;
          }
          @media(min-width: 991px) {
            margin-top: 230px;
            font-size: 37px;
            line-height: 45px;
          }

        }

        a {
          margin-top: 35px;
        }
      }
    }

    .pic {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: rgba(82, 105, 128, 0.42);
      }
    }
  }
}
