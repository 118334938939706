.contact-form {
  form {
    margin: 20px auto 0;

    input[type="text"],
    input[type="email"],
    textarea {
      font-size: 16px;
      margin-top: 10px;
      outline: none;
      color: #888;
      background: white;
      padding: 10px 16px;
      border: 1px solid #cbcdd2;
      width: 100%;
      border-radius: 4px;

      @include transition(all .2s);

      @include placeholder {
        color: #B8BBC7;
      }

      @media(min-width: 768px) {
        font-size: 18px;
      }

      &:focus {
        border-color: #656565;

        @include placeholder {
          color: #666;
        }
      }
    }

    input[type="submit"] {
      background: linear-gradient(to bottom, #6fb1f5 0%, #3b97f7 100%);
      box-shadow: rgba(23, 43, 99, 0.24) 0 7px 28px;
      width: 100%;
      padding: 8px 10px;
      text-align: center;
      outline: none;
      border: none;
      cursor: pointer;
      margin-top: 23px;
      font-weight: 500;
      border-radius: 4px;
      font-size: 18px;
      color: #fff;
      text-shadow: 1px 1px 0px rgba(106, 132, 158, 0.6);

      @include transition(all .2s);

      &:hover {
        color: #fff;
        box-shadow: none;
        text-shadow: none;
      }
    }
  }
}
